<template>
   <b-card fluid>
          <b-img :src="require('@/assets/images/illustration/social.png')" class="position-absolute" fluid alt="" style="right: 0px;top: 0px;"></b-img>
 
              <b-row class="mb-1">
                  <b-col cols="12">
          <div class="crownBalanceTitle">Total Balance</div>
                  </b-col>
              </b-row>

          <b-card-text class="crownBalanceExp">
         <span class="border-right mr-1"><b-img :src="require('@/assets/images/illustration/crown.svg')" fluid alt=""/>
         {{exp.crowns}} </span> <span><b-img :src="require('@/assets/images/illustration/goblet.svg')" fluid alt=""/> {{exp.goblets}} </span>
        </b-card-text>

      </b-card>
</template>
<script>
import {BCard,BCardText,BButton,BRow,BCol,BCardBody,BCardImg,BImg,BBadge} from "bootstrap-vue"
import SellerService from '@/services/SellerService';

import Ripple from "vue-ripple-directive"
export default {
  directives:{Ripple},
  components:{BCard,BCardText,BButton,
  BRow,BCol,BCardBody,BCardImg,BImg,BBadge},
  data(){
    return{
      exp:0
    }
  },
  mounted(){
        
    this.getExpbyUser();


  },
  methods:{
      getExpbyUser:async function(){
    const ex=(await SellerService.getExpbyUser(JSON.parse(localStorage.getItem("auth")).discordId)).data
    this.exp=ex
  },
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
.badgeStore{ 
display:flex;
width: 40px;
height: 40px;
justify-content: center;
align-items: center;
background: #223433;
border-radius: 4px;
top: 20.31%;
left:20.31%;
bottom: 20.31%;
margin-top: 3.45%;
}
.crownBalanceTitle{
  font-style: normal;
  margin-top: 3.45%;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.015em;
color: #9F9F9F;
}
.crownBalanceExp{
  font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 40px;
letter-spacing: 0.015em;
color: #FFFFFF;
}
</style>