<template>
     <b-card
        no-body >
        <b-img
        :src="require('@/assets/images/headers/banner.png')"
        alt="Card image cap"
        class="card-img-top"
      />
      </b-card>
</template>
<script>
import {
  BCard,BCardBody, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs,BTab, BNavItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,BCardBody
  },
  directives: {
    Ripple,
  },
  data(){
    return{
    }
  },
}
</script>

