<template>
<div>
    <b-row class="match-height">
        <b-col xl="4" lg="4" md="6" sm="12"  v-for="(quest,i) in quests" :key="i">
              <b-card
               no-body
               class="cardStore cursor-pointer"
               style="background: #181C21;"
               @click="notstarted==0?openQuest(quest):openMsg(quest)">
      <b-img
        :src="quest.options.headerUrl"
      />
       <b-card-text class="text-right position-absolute" style="right: 10px;top: 10px;">
        <b-badge class="badgeRounded">{{quest.options.items.length}} Quests</b-badge></b-card-text>
      <b-card-body>
        <div class="text-center">
             <b-card-title class="mb-50 font-weight-bolder">
            {{$t("curlang")=='en'?quest.options.title:quest.options.titlees}}
          </b-card-title>
          </div>
          <div class="text-center">
           <b-card-text>
            <div v-html="$t('curlang')=='en'?quest.options.description:quest.options.descriptiones"></div>
          </b-card-text>
          </div>
            <div class="text-center">
        <p class="card-text mb-25 mt-3">
          <img src="@/assets/playground_assets/Ellipse2.png" alt="Snow"> 
          {{notstarted==0?$t("questregister.duedate")+' '+quest.options.shortDate
          :$t("questregister.startdate")+' '+startShortDate}}
        </p>
      </div>
      </b-card-body>
  </b-card>
        </b-col>
    </b-row>
</div>
</template>
<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle,VBTooltip, BMediaBody,BRow,BCol,BProgress,BCardFooter,BBadge
} from 'bootstrap-vue'

export default {
    props:["quests","progress","notstarted"],
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMediaBody,
    BRow,BCol,BProgress,BCardFooter,BBadge
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return{
    }
  },
  methods:{
       getQuestProgressFilter:function(questId){
          let arreglo=[]
         if(this.progress)
         {
          arreglo=this.progress.filter(value=> value.questId==questId)
         try {
          if(arreglo[0]["progress"]){
            return arreglo[0]
          }
         } catch (error) {
          return {progress:0}
         }

         }
        return {progress:0}
      },
    openQuest:function(q){
         let  dataU={quest:q};
       localStorage.setItem('dataquest',null);
      localStorage.removeItem('dataquest'); 
       localStorage.setItem('dataquest',JSON.stringify(dataU));
      this.$router.push({ name: 'viewitems'})
    },
    openMsg:function(q){
         this.$swal({
          title: "",
          text: this.$t("curlang")=='en'?"This adventure will be available starting "+q.options.startShortDate:
          "Esta aventura estará disponible apartir del "+q.options.startShortDatees,
          icon: "error",
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
    }
  }
}
</script>
<style scoped>
.badgeRounded{
  padding: 10px 24px;
  gap: 8px;
  background: linear-gradient(87.2deg, rgba(164, 136, 255, 0.56) 2.33%, rgba(74, 44, 174, 0.581) 51.02%, rgba(39, 8, 142, 0.7) 94.9%);
  border-radius: 48px;
}
.ellipse{
width: 16px;
height: 16px;
background: linear-gradient(87.2deg, rgba(164, 136, 255, 0.8) 2.33%, rgba(74, 44, 174, 0.83) 51.02%, #27088E 94.9%);
flex: none;
order: 0;
flex-grow: 0;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
.cardStore {
overflow: hidden;
&:hover {
      transform: scale(1.05);
    box-shadow: 0px 0px 10px #82868b !important;
    }
}
</style>
