<template>
      <b-card no-body class="overflow-hidden bg-custom-1"  fluid>
    <b-row no-gutters class="match-height mt-1">
      <b-col xl="3" lg="4" md="4" sm="12" xs="12" class="position-relative" v-if="(access==1)" >
        <b-card-img :src="image" :style="!isMd()?'width:260px;height: 182px;':'width:215px;height: 160px;'" alt="Image"></b-card-img>
      </b-col>
      <b-col xl="2" lg="3" md="3" sm="12" xs="12" class="position-relative mr-5" v-if="(access==2)" >
        <b-card-img :src="image" style="width:226px;height: 172px;" alt="Image" :class="isXL()?'ml-5 mr-3':isLg()?'mr-2':'mr-3'" ></b-card-img>
      </b-col>
      <b-col  xl="5" lg="6"  md="7" class="mb-2"  >
          <div class="textTitle mb-1 mt-1">{{title}}</div>
          <b-card-text class="cardTextParagraph">
            {{paragraph}}
          </b-card-text>
      </b-col>
      <b-col xl="3" md="1" sm="12" xs="12"  v-if="(access==1)"></b-col>
      <b-col xl="3" lg="11" md="11" sm="11" xs="11" v-if="(access==2)" class="mr-1 ml-1 mt-1 float-right">
        <SmallCardCrown/>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {BCard,BCardText,BButton,BRow,BCol,BCardBody,BCardImg,BImg} from "bootstrap-vue"
import SellerService from '@/services/SellerService';
import SmallCardCrown from "@/components/SmallCardCrown.vue"

import Ripple from "vue-ripple-directive"
export default {
    directives:{Ripple},
    components:{BCard,BCardText,BButton,SmallCardCrown,
    BRow,BCol,BCardBody,BCardImg,BImg},
    props:["image","title","paragraph","access"],
    data(){
      return{
      }
    },
    mounted(){
    },
    methods:{
      isXL:function(){
       
       return ['xl'].includes(this.$store.getters['app/currentBreakPoint']);
     },
     isLg:function(){
       
       return ['lg'].includes(this.$store.getters['app/currentBreakPoint']);
     },
     isMd:function(){
       
       return ['md'].includes(this.$store.getters['app/currentBreakPoint']);
     },
      isMobile:function(){
       
       return ['xs','sm'].includes(this.$store.getters['app/currentBreakPoint']);
     }
    }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
.textTitle{
// font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 56px;
line-height: 64px; 
letter-spacing: 0.015em;
color: #FFFFFF;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.cardTextParagraph{
  
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.015em;
color: #9F9F9F;


}
.bg-custom-1 {
    background-color: #181C21!important;
}
.badgeStore{ 
display:flex;
width: 40px;
height: 40px;
justify-content: center;
align-items: center;
background: #223433;
border-radius: 4px;
top: 20.31%;
left:20.31%;
bottom: 20.31%;
margin-top: 3.45%;
}
.crownBalanceTitle{
    font-style: normal;
    margin-top: 3.45%;
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.015em;
color: #9F9F9F;
}
.crownBalanceExp{
    font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 40px;
letter-spacing: 0.015em;
color: #FFFFFF;
}
</style>