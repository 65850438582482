<template>
  <div>
    <div class="relative rounded-xl bg-[#181C21] p-10 w-full overflow-hidden">
      <div class="flex flex-col md:items-center ">
        <h1 class="text-2xl md:text-5xl font-semibold text-white mb-2 md:mb-4">Explore Airdrops</h1>
        <p class="text-base md:text-xl text-white/70 w-1/1 md:w-2/3 text-center">Discover the most promising airdrops across Solana
          and various
          blockchains at no cost! Additionally, sign up for automated farming to ensure you secure those valuable coins
          effortlessly!</p>
      </div>

      <div class="absolute left-0 top-0">
        <img src="@/assets/images/illustration/quest.png" alt="" class="opacity-5 lg:opacity-100" />
      </div>
    </div>


    <!--headerpage/-->
    <!-- <div v-if="['sm', 'xs'].includes($store.getters['app/currentBreakPoint'])" class="text-center">
      <h3 class="text-white font-weight-bolder">Explore Airdrops</h3>
    </div>
    <header-part v-if="['sm', 'xs'].includes($store.getters['app/currentBreakPoint']) ? false : true"
      :title="'Explore Airdrops'" :image="require('@/assets/images/illustration/quest.png')" :access="1"
      :paragraph="'Complete social and creative quests to earn rewards. Your rewards can be used to buy different Items from Eliteemperors store.'"></header-part> -->

    <SkeletonCard v-if="loadingFeatured" class="mt-20" />
    <Airdrop :quests="quests" :progress="progress" :notstarted="0" v-else class="mt-20" />

  </div>
</template>
<script>
import { BCard, BCardText, BCollapse, BTabs, BTab, BNavItem, BButton, BSpinner, BRow, BCol, BCardBody, BCardImg } from "bootstrap-vue"
import Quest from "@/components/Quest.vue"
import Ripple from "vue-ripple-directive"
import SellerService from "@/services/SellerService";
import Headerpage from "@/components/Headerpage.vue"
import HeaderPart from "@/components/HeaderPart.vue"
import SkeletonCard from "@/components/SkeletonCard.vue";
import Airdrop from "@/components/Airdrop.vue";
export default {
  directives: { Ripple },
  components: {
    BCard, BCardText, BCollapse, BTabs, BTab, BNavItem, BButton, Quest, BSpinner, Headerpage,
    BRow, BCol, BCardBody, BCardImg, HeaderPart, SkeletonCard, Airdrop
  },
  data() {
    return {
      quests: [],
      questinprogress: [],
      loadingFeatured: true,
      progress: [],
      notStarted: [],
      completed: []
    }
  },
  mounted() {
    this.getQuests()
    this.getQuestProgress()
    this.insertlg()
  },
  methods: {
    getQuests: async function () {
      const qu = (await SellerService.getQuests(JSON.parse(localStorage.getItem("auth")).discordId, 1)).data
      var date = new Date();
      var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
      this.quests = qu;
      this.loadingFeatured = false
    },
    getQuestProgress: async function () {
      const pro = (await SellerService.getQuestProgress(JSON.parse(localStorage.getItem("auth")).discordId)).data
      this.progress = pro
    },
    insertlg: async function () {
      const d = (await SellerService.insertLgbyUser(JSON.parse(localStorage.getItem("auth")).discordId, this.$t("curlang")))
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';

.titles {
  color: #FFFFFF;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
</style>