<template>
    <div>
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4" v-if="quests.length > 0">
            <div class="w-full h-full rounded-lg cursor-pointer shadow
            transition-all duration-700 hover:scale-95 hover:border bg-[#181C21]" v-for="(quest, i) in quests" :key="i"
                @click="openAirdrop(quest)">
                <img class="w-full h-60 rounded-t-lg" :src="quest.options.headerUrl" alt="Image for the item" />
                <div class="flex flex-col justify-center items-center gap-1 mx-4 my-4">
                    <p class="text-xl font-bold text-center text-white">
                        {{ $t("curlang") == 'en' ? quest.options.title : quest.options.titlees }}
                    </p>
                    <p class="text-sm md:text-base text-center text-white/70"
                        v-html="$t('curlang') == 'en' ? quest.options.description : quest.options.descriptiones">
                    </p>
                </div>
            </div>
        </div>
        <div class="min-h-96 flex items-center justify-center bg-[#181C21] rounded-lg" v-else>
            <div class="rounded-lg p-8 m-4 w-full lg:w-1/2">
                <h1 class="text-2xl text-white font-bold mb-4 text-center">Not Airdop Options Yet!</h1>
                <div class="flex justify-center space-x-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="w-8 h-8 text-white">
                        <path
                            d="M17.8 19.2 16 11l3.5-3.5C21 6 21.5 4 21 3c-1-.5-3 0-4.5 1.5L13 8 4.8 6.2c-.5-.1-.9.1-1.1.5l-.3.5c-.2.5-.1 1 .3 1.3L9 12l-2 3H4l-1 1 3 2 2 3 1-1v-3l3-2 3.5 5.3c.3.4.8.5 1.3.3l.5-.2c.4-.3.6-.7.5-1.2z">
                        </path>
                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="w-8 h-8 text-white">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="w-8 h-8 text-white">
                        <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                        <line x1="16" x2="16" y1="2" y2="6"></line>
                        <line x1="8" x2="8" y1="2" y2="6"></line>
                        <line x1="3" x2="21" y1="10" y2="10"></line>
                    </svg></div>
            </div>
        </div>
    </div>
</template>
<script>
import SkeletonCard from "@/components/SkeletonCard.vue";
export default {
    components: { SkeletonCard },
    props: ["quests", "progress", "notstarted"],
    data() {
        return {
        }
    },
    methods: {
        getQuestProgressFilter: function (questId) {
            let arreglo = []
            if (this.progress) {
                arreglo = this.progress.filter(value => value.questId == questId)
                try {
                    if (arreglo[0]["progress"]) {
                        return arreglo[0]
                    }
                } catch (error) {
                    return { progress: 0 }
                }

            }
            return { progress: 0 }
        },
        openAirdrop: function (q) {
            let dataU = { quest: q };
            localStorage.setItem('dataair', null);
            localStorage.removeItem('dataair');
            localStorage.setItem('dataair', JSON.stringify(dataU));
            this.$router.push({ name: 'viewairdrop' })
        },
        openMsg: function (q) {
            this.$swal({
                title: "",
                text: this.$t("curlang") == 'en' ? "This adventure will be available starting " + q.options.startShortDate :
                    "Esta aventura estará disponible apartir del " + q.options.startShortDatees,
                icon: "error",
                showClass: {
                    popup: "animate__animated animate__fadeIn",
                },
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
            });
        }
    }
}
</script>